<template>
	<MainLayout>
		<div class="checkout">
			<b-form class="form" @submit.stop.prevent="onSubmit">
				<b-container>
					<div class="page-top">
						<b-row align-v="center" class="flex-md-row-reverse">
							<b-col md="6" lg="4" class="text-md-right mb-3">
								<b-link @click="$router.push({ name: 'OrderView' })" href="#" class="font-weight-bold">
									Back to Menu
								</b-link>
							</b-col>
							<b-col md="6" lg="8">
								<h1 class="text-primary mb-3">Checkout Details</h1>
							</b-col>
						</b-row>
					</div>

					<h4 class="form-title mb-2 mb-md-3">
						Your Information
					</h4>

					<b-row>
						<b-col lg="6">
							<b-form-group id="input-group-firstName" label="First Name *" label-for="firstName">
								<b-form-input
									v-model="form.customer.first_name"
									id="firstName"
									:state="validateState('first_name', 'customer')"
									aria-describedby="input-first-name"
									placeholder="Please enter your first name"
								/>
								<b-form-invalid-feedback id="input-first-name">
									This is a required field and must be at least 3 characters.
								</b-form-invalid-feedback>
							</b-form-group>
						</b-col>

						<b-col lg="6">
							<b-form-group id="input-group-lastName" label="Last Name *" label-for="lastName">
								<b-form-input
									v-model="form.customer.last_name"
									id="lastName"
									:state="validateState('last_name', 'customer')"
									aria-describedby="input-1-last-name"
									placeholder="Please enter your last name"
								/>
								<b-form-invalid-feedback id="input-1-last-name">
									This is a required field and must be at least 3 characters.
								</b-form-invalid-feedback>
							</b-form-group>
						</b-col>

						<b-col lg="6">
							<b-form-group id="input-group-email" label="Email *" label-for="Email">
								<b-form-input
									v-model="form.customer.email"
									id="Email"
									:state="validateState('email', 'customer')"
									aria-describedby="input-email"
									placeholder="Please enter your email address"
								/>
								<b-form-invalid-feedback id="input-email">
									This is a required field, should be a valid email address
								</b-form-invalid-feedback>
							</b-form-group>
						</b-col>

						<b-col lg="6">
							<b-form-group id="input-1-last-phone" label="Phone *" label-for="Phone">
								<b-form-input
									v-model="form.customer.phone"
									id="Phone"
									:state="validateState('phone', 'customer')"
									aria-describedby="input-phone"
									placeholder="Please enter your phone number"
								/>
								<b-form-invalid-feedback id="input-phone">
									This is a required field
								</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
					</b-row>
					
					<h4 class="form-title mb-2 mb-md-3">Order Type</h4>
					<div class="settings px-3 pb-4">
						<ul class="nav nav-pills">
							<li class="nav-item" v-for="availableDiningOption in availableDiningOptions" :key="availableDiningOption.id">
								<a class="nav-link" @click="setNewDiningOption(availableDiningOption.id)"
									 :class="{'active': chosenDiningOption===availableDiningOption.id}">{{ availableDiningOption.name }}</a>
							</li>
						</ul>
						<div class="settings-meta">
							<p v-html="chosenDiningOptionObject.description" v-if="chosenDiningOptionObject.description"></p>
						</div>
		
						<b-form-group
							v-if="chosenDiningOptionObject.is_deliverable"
							id="input-group-2"
							label-for="input-2"
							class="mb-4"
						>
						<template #label>
							<div class="d-flex align-items-center">
								<span>Delivery Address *</span>
								<span v-if="addresses.length > 0 && userId" class="ml-auto">
									<b-button variant="primary" @click="toggleAddressSelection()">{{enableAddressTextFieldLabel}}</b-button>
								</span>
							
							</div>
						</template>
						<div v-if="addresses.length > 0 && userId && !enableAddressTextField" class="address-container">
							<b-form-select v-model="delivery_address" :options="addressOptions"></b-form-select>
						</div>
		
						<div v-else class="address-container">
							<!-- <b-form-select id="form-country-region" class="mt-2" v-model="delivery_address.country" :options="countryOptions">
								<template #first>
									<b-form-select-option
									  :value="null"
									  disabled
									>
									  -- Please select a country --
									</b-form-select-option>
								  </template>
							</b-form-select> -->
							<!-- <div> -->
								<!-- <b-form-select id="form-state" class="mt-2" v-if="delivery_address.country === 'US'" v-model="delivery_address.state" :options="statesOptions">
									<template #first>
										<b-form-select-option
										  :value="null"
										  disabled
										>
										  -- Please select a state --
										</b-form-select-option>
									  </template>
								</b-form-select> -->
								<!-- <b-form-input id="form-state" class="mt-2" v-model="delivery_address.state" placeholder="Enter State"></b-form-input> -->
							<!-- </div> -->
							<b-form-input id="form-address" class="mt-2" v-model="delivery_address.address_1" :placeholder="address_fields?.address_1"></b-form-input>
							<span v-if="isAddressMissing"><small class="text-danger error-label">Address required *</small></span>
							<b-form-input id="form-appartment" class="mt-2" v-model="delivery_address.address_2" :placeholder="address_fields?.address_2"></b-form-input>
							<b-form-input id="form-city" class="mt-2" v-model="delivery_address.city" :placeholder="address_fields?.city"></b-form-input>
							<b-form-input id="form-state" class="mt-2" :disabled="address_fields?.state === null" :hidden="address_fields?.state === null" v-model="delivery_address.state" :placeholder="address_fields?.state"></b-form-input>
							<b-form-input id="form-postalcode" class="mt-2" v-model="delivery_address.postal_code" :placeholder="address_fields?.postal_code"></b-form-input>
							<!-- <b-form-input
								id="input-2"
								v-model="delivery_address"
								v-on:input="$v.delivery_address.$touch"
								v-bind:class="getClass($v.delivery_address)"
								required
								placeholder="Enter address"
								@input="search"
							></b-form-input>
							<b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.delivery_address.required">This is a required
								field
							</b-form-invalid-feedback>
							<div class="address-container__dropdown">
								<ul>
									<li v-for="place in searchedPlaces" :key="place.place_id">
									 <span @click="selectPlace(place.display_name)">{{ place.display_name }}</span>
									</li>
								</ul>
							</div> -->
						</div>
		
						</b-form-group>
						<b-form-group class="mb-2">
							<b-form-radio-group
								id="radio-group-2"
								v-model="pickupSettings"
								name="pickup_when"
								class="d-block"
								value="now"
							>
								<b-form-radio value="now" class="settings-item"><strong>NOW</strong> (30-35 Min)</b-form-radio>
								<b-form-radio v-if="isScheduleEnabled()" value="later" class="settings-item"><strong>Schedule</strong> for
									later
								</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
		
						<div>
							<b-form-group
								id="select-group-1"
								v-if="pickupSettings === 'later' && isScheduleEnabled()"
								label="Select Date"
								label-for="select-1"
							>
								<b-form-datepicker v-model="delivery_date" :min="delivery_date_min" :max="delivery_date_max"
																	 locale="en"></b-form-datepicker>
							</b-form-group>
							<b-form-group id="select-group-2" v-if="pickupSettings==='later' && isScheduleEnabled()" label="Select Time"
														label-for="select-2">
								<b-form-timepicker
									v-model="delivery_time"
									v-if="pickupSettings === 'later'"
									locale="en"
									label-no-time-selected
									:state="!deliveryTimeLimitExceed"></b-form-timepicker>
								<b-form-invalid-feedback id="input-2-live-feedback" v-if="deliveryTimeLimitExceed">Please select an appropiate delivery time. The delivery time should not be before {{ timeLimit}}
								</b-form-invalid-feedback>
							</b-form-group>
						</div>
						
					</div>
					<!-- <b-button v-if="chosenDiningOptionObject.is_deliverable" variant="primary" class="w-100 mb-5" squared size="lg" @click="saveDinnerOption" :disabled="deliveryTimeLimitExceed">
						<span>Add Address</span>
					</b-button> -->

					<h4 class="form-title mb-2 mb-md-3">
						Payment Method
					</h4>

					<p class="form-meta mb-0 mb-md-4">
						Please choose one of the following payment types you would like to use.
					</p>

					<b-form-group class="pb-md-2">
						<b-form-radio-group
							v-model="form.payment_type"
							id="radio-group-pay"
							name="radio-sub-component"
							class="d-block"
							:state="payment_type_state"
							:options="paymentTypeOptions"
							aria-describedby="input-1-payment_type"
						>
							<b-form-invalid-feedback :state="payment_type_state">Please select one</b-form-invalid-feedback>
						</b-form-radio-group>
						<b-form-invalid-feedback id="input-1-payment_type">
							This is a required choice
						</b-form-invalid-feedback>
					</b-form-group>
					<b-form-group :class="stripeFormClass()">
						<div class="form-group">
							<label for="">CardHolder Name</label>
							<input type="text" class="form-control" v-model="stripeCardHolderName" placeholder="Cardholder Name">
						</div>
						<div id="card-element">
						</div>
					</b-form-group>
					<b-form-group v-if="showCardInputDetails">
						<b-row v-if="paymentProcessor !== 'Stripe'">
							<b-col lg="6" sm="12" >
								<label for="input-1-payment_details">Card Holder *</label>
								<b-form-input class="mb-4" id="input-1-payment_details" :state="validateState('name', 'payment_details')" v-model="form.payment_details.name" placeholder="Cardholder Name" aria-describedby="input-1-payment_details"></b-form-input>
								<b-form-invalid-feedback class="mt-n4 mb-4" id="input-1-payment_details">
									This is a required field
								</b-form-invalid-feedback>
							</b-col>
							<b-col lg="6" sm="12">
								<label for="input-2-payment_details">Card Number *</label>
								<b-form-input class="mb-4" id="input-2-payment_details" :state="validateState('number', 'payment_details')" v-model="form.payment_details.number" placeholder="Card Number" aria-describedby="input-2-payment_details" @input="addSpace"></b-form-input>
								<b-form-invalid-feedback class="mt-n4 mb-4" id="input-2-payment_details" :state="!error.number">
									Invalid card number
								</b-form-invalid-feedback>
							</b-col>
							<b-col lg="6" sm="12">
								<label for="input-3-payment_details">CVV *</label>
								<b-form-input class="mb-4" id="input-3-payment_details" :state="validateState('cvv', 'payment_details')" v-model="form.payment_details.cvv" placeholder="CVV" aria-describedby="input-3-payment_details"></b-form-input>
								<b-form-invalid-feedback class="mt-n4 mb-4" id="input-3-payment_details" :state="!error.cvv">
									Invalid cvv
								</b-form-invalid-feedback>
							</b-col>
							<b-col lg="6" sm="12">
								<label for="input-4-payment_details">Expiration *</label>
								<b-form-input class="mb-4" id="input-4-payment_details" :state="validateState('expiration', 'payment_details')" v-model="form.payment_details.expiration" placeholder="00/00" aria-describedby="input-4-payment_details" @input="validateCardExpiry"></b-form-input>
								<b-form-invalid-feedback class="mt-n4 mb-4" id="input-4-payment_details" :state="!error.expiration">
									Invalid expiration date
								</b-form-invalid-feedback>
							</b-col>
						</b-row>
					</b-form-group>
				</b-container>

				<div class="bg-white pt-3 pt-md-4">
					<b-container>
						<h4 class="form-title mb-3">
							Your Order
						</h4>

						<div class="order-list">
							<div v-for="(item, index) in items" :key="index" class="order-item">
								<div class="order-item-top d-flex">
									<div class="order-quant">
										<span>{{item.quantity}}</span>
									</div>
									<h6 class="order-title">
										{{ item.name }}
									</h6>
									<div class="order-price">{{currency}} {{ item.price }}</div>
								</div>
							</div>
						</div>

						<div class="order-bottom bg-white position-static">
							<div class="order-total px-0">
								<div class="order-total-tr">
									<div class="order-item-top d-flex justify-content-between">
										<div>
											Subtotal
										</div>
										<div class="order-price">{{currency}} {{ subtotal.toFixed(2) }}</div>
									</div>
								</div>

								<div class="order-total-tr" v-if="tipAmount">
									<div class="order-item-top d-flex justify-content-between">
										<div>
											Tip
										</div>
										<div class="order-price">{{currency}} {{ tipAmount.toFixed(2) }}</div>
									</div>
								</div>

								<div class="order-total-tr">
									<div class="order-item-top d-flex justify-content-between">
										<div>
											Tax
										</div>
										<div class="order-price">{{currency}} {{ totalTax.toFixed(2) }}</div>
									</div>
								</div>

								<div class="order-total-tr">
									<div class="order-item-top d-flex justify-content-between">
										<div>
											<strong class="font-weight-bold text-primary">Total</strong>
										</div>
										<div class="order-price">
											<strong class="font-weight-bold text-primary">{{currency}} {{ grand_total.toFixed(2) }}</strong>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="form-bottom">
							<b-row class="pb-3">
								<b-col md="6" lg="4">
									<b-button
										variant="outline-primary"
										class="w-100 mb-4"
										size="lg"
										@click="$router.push({ name: 'OrderView' })"
									>
										<strong>Back to Menu</strong>
									</b-button>
								</b-col>
								<b-col md="6" lg="8">
									<!-- <b-button variant="primary" v-b-modal.modal-order-text class="w-100 mb-4" size="lg"> -->
									<!--   <strong>Complete Order</strong> -->
									<!-- </b-button> -->
									<!--									<b-button variant="primary" @click="checkoutDone" class="w-100 mb-4" size="lg">
									-->
									<!--										<strong>Complete Order</strong>-->
									<!--									</b-button>-->
									<b-button v-if="!isStoreClosed" variant="primary" type="submit" class="w-100 mb-4" size="lg" :disabled="loading || (deliveryTimeLimitExceed && pickupSettings === 'later' && isScheduleEnabled())">
										<!-- <span class="d-flex w-100"> -->
											<b-spinner small class="mr-2" v-if="loading"></b-spinner>
											<strong>Complete Order</strong>
										<!-- </span> -->
									</b-button>

									<b-button v-else variant="light" class="w-100 mb-4" size="lg">
										<strong>Store is Closed</strong>
									</b-button>
								</b-col>
							</b-row>
						</div>
					</b-container>
				</div>
			</b-form>
		</div>

		<!-- <b-button v-b-modal.modal-order-text>Launch demo modal</b-button> -->

		<b-modal @hide="finishOrder" id="modal-order-text">
			<template #modal-header>
				<div class="modal-top p-3">
					<h4 class="modal-title mb-2">
						Your order has been received!
					</h4>
				</div>
			</template>

			<template #default>
				<div class="settings px-3 pb-4">
					<div class="settings-meta">
						<p>
							Your order has been received. <br/>
							We have sent an email confirmation to your email {{ form.email }}
						</p>
					</div>
				</div>
			</template>

			<template #modal-footer="{ close }">
				<b-button variant="primary" class="w-100" squared size="lg" @click="close()">
					<span>Close</span>
				</b-button>
			</template>
		</b-modal>
	</MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import { createOrder } from "../helpers/apiHelper";
import { validationMixin } from "vuelidate";
import { required, minLength, email, requiredIf } from "vuelidate/lib/validators";
import {mapGetters} from 'vuex'
import deliveryOptionMixin from "@/mixins/deliveryOption.mixin";
import indexDbHelper from "@/helpers/indexDbHelper";

export default {
	name: "FormView",
	mixins: [validationMixin, deliveryOptionMixin],
	components: {
		MainLayout
	},
	data () {
		return {
			showSpinner: false,
			form: {
				customer: {
					first_name: null,
					last_name: null,
					email: null,
					phone: null
				},
				payment_type: null,
				payment_details:{
					"name":null,
					"expiration":null,
					"cvv":null,
					"number":null
				}
			},
			error:{
				number:false,
				cvv:false,
				expiration:false
			},
			loading:false,
			stripeCardHolderName: null,
			stripe: null,
			cardElement: null,
			token: null,
			stripeCardElement: null,
			elements: null,
			// paymentTypeOptions: [
			// 	{ value: "cash", text: "Cash on delivery" },
			// 	{ value: "card", text: "Credit card on delivery" }
			// ]
			paymentProcessor: null,
			address_fields: null
		};
	},
	validations: {
		form: {
			customer: {
				first_name: {
					required,
					minLength: minLength(3)
				},
				last_name: {
					required,
					minLength: minLength(3)
				},
				email: {
					required,
					email
				},
				phone: {
					required
				}
			},
			payment_type: {
				required
			},
			payment_details:{
				name: {
					required: requiredIf(function () {
						return this.paymentProcessor == 'USAePay';
					})
				},
				expiration: {
					required: requiredIf(function () {
						return this.paymentProcessor == 'USAePay';
					})
				},
				cvv: {
					required: requiredIf(function () {
						return this.paymentProcessor == 'USAePay';
					})
				},
				number: {
					required: requiredIf(function () {
						return this.paymentProcessor == 'USAePay';
					})
				}
			}
		}
	},
	mounted () {
		this.address_fields = this.$store.getters.getStoreAddressFields;

		const availablePaymentMethods = this.$store.getters.availablePaymentMethods;
		// check to see if stripe is available then get publishable_key from the payment method Stripe
		const stripePaymentMethod = availablePaymentMethods.find(paymentMethod => paymentMethod.payment_processor === 'Stripe');
		if (stripePaymentMethod){
			this.stripe = Stripe(stripePaymentMethod.publishable_key);
			this.elements = this.stripe.elements();
			this.createStripeElement();
		}
		const lastUsedAlias = localStorage.getItem("lastUsedAlias");
		if (!this.items.length) {
			this.$router.push({ name: "OrderView", params: { storeAlias: lastUsedAlias } });
		}
		// update details of logged in customer
		if(Object.keys(this.loggedUser).length > 0){
			this.form.customer.first_name = this.loggedUser.first_name
			this.form.customer.last_name = this.loggedUser.last_name
			this.form.customer.phone = this.loggedUser.phone
			this.form.customer.email = this.loggedUser.email
		}
		
		// selecting the first payment by default
		if(availablePaymentMethods && availablePaymentMethods.length > 0){
			this.form.payment_type = availablePaymentMethods[0].id
		}
	},
	watch:{
		loggedUser:{
			handler(loggedUser){
			// update details of logged in customer
			if(Object.keys(loggedUser).length > 0){
				this.form.customer.first_name = loggedUser.first_name
				this.form.customer.last_name = loggedUser.last_name
				this.form.customer.phone = loggedUser.phone
				this.form.customer.email = loggedUser.email
			}
			},deep:true
		},
		'form.payment_type':{
			handler(){
				const availablePaymentMethods = this.$store.getters.availablePaymentMethods;
				const paymentType = availablePaymentMethods.find(paymentType => paymentType.id === this.form.payment_type)
				
				if(paymentType){
					this.paymentProcessor = paymentType.payment_processor
				}
			}
		}
	},
	methods: {
		parseDeliveryAddress () {
			const address = this.selectedDeliveryAddress;
			let address_str = "";

			if (address) {
				address_str += address.address_1 ? `${address.address_1}, ` : "";
				address_str += address.address_2 ? `${address.address_2}, ` : "";
				address_str += address.city ? `${address.city}, ` : "";
				address_str += address.state ? `${address.state}, ` : "";
				address_str += address.postal_code ? `${address.postal_code}, ` : "";
				address_str += address.country ? `${address.country} ` : "";
			}
			
			return address_str;
		},
		tokenize: async function() {
			const {token, error} = await this.stripe.createToken(this.stripeCardElement, {
				name: this.stripeCardHolderName
			});
			return {token, error};
		},
		async checkoutDone () {
			this.chosenDiningOptionObject.is_deliverable && this.saveDinnerOption();
			var paymentDetails = {}
			if (this.paymentProcessor === 'Stripe') {
				const {token, error} = await this.tokenize();
				if (error) {
					console.log("ERROR", error);
					return;
				}
				paymentDetails = {
					token: token.id
				};
			}
			else {

				if(this.showCardInputDetails){
					paymentDetails = {...this.form.payment_details}
					// changing string values to int
					try{
						paymentDetails.cvv = parseInt(this.form.payment_details.cvv)
						paymentDetails.number = parseInt(this.form.payment_details.number.replaceAll(" ",''))
						paymentDetails.expiration = this.form.payment_details.expiration.replace("/",'')
					}
					catch(err){
						this.error.number = true
						this.error.cvv = true
						this.error.expiration = true
						return 
					}
				}
			}
			// updating delivery address fields
			if(this.selectedDeliveryAddress){
				this.selectedDeliveryAddress['first_name'] = this.form.customer.first_name
				this.selectedDeliveryAddress['last_name'] = this.form.customer.last_name
				this.selectedDeliveryAddress['phone'] = this.form.customer.phone
			}

			this.loading = true
			createOrder({
				items: this.items,
				discount_total: 0,
				tax_total: this.totalTax,
				subtotal: this.subtotal,
				grand_total: this.grand_total,
				open_date: new Date().toISOString(),
				dining_option: this.dining_option_name,
				customer:
					{
						first_name: this.form.customer.first_name,
						last_name: this.form.customer.last_name,
						email: this.form.customer.email,
						phone: this.form.customer.phone,
						address: this.selectedDeliveryAddress ? {...this.selectedDeliveryAddress}:null
					} || null,
				// payment_type: this.form.payment_type || null,
				serve_at: this.serve_at ? this.serve_at : null,
				delivery_address: this.parseDeliveryAddress(),
				tips_total: this.tipAmount,
				related_store: this.store.id,
				desirable_payment_type: this.form.payment_type,
				payment_details: this.showCardInputDetails ? paymentDetails : null
			})
			.then(()=>{
				console.log("ORDER CREATED SUCCESSFULLY")
				indexDbHelper.clearAllData()
				.then(()=>{
					this.$store.commit("setDeliveryAddress", {
						country:'',
						state:'',
						first_name:'',
						last_name:'',
						city:'',
						postal_code:'',
						phone:'',
						address_1:'',
						address_2:''
					});
					this.$bvModal.show("modal-order-text");
					this.loading = false
				})
			}).catch(err=>{
				this.loading = false
				console.log("ERRROR IN ORDER CREATION")
				if(err.status === 400 && err.data==='Credit card has expired.'){
					this.error.number =false
					this.error.cvv =false
					this.error.expiration = true
				}
				else if(err.status === 400 && (err.data==='Invalid Card Number (3)' || err.data.includes('Card Number') )){
					this.error.number =true
					this.error.cvv =false
					this.error.expiration = false
				}
				else if(err.status === 400){
					this.error.number = true
					this.error.cvv = true
					this.error.expiration = true
				}
			})

		},
		async finishOrder () {
			await this.$store.dispatch("setItems", []);
			await this.$router.push({ name: "OrderView" });
		},
		validateState (name, parentProperty) {
			const { $dirty, $error } = parentProperty ? this.$v.form[parentProperty][name] : this.$v.form[name];
			return $dirty ? !$error : null;
		},
		onSubmit () {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				console.log("ERROR GENERATED FORM NOT SUBMITTTED",this.$v.form)
				return;
			}
			if (this.isStoreClosed) {
				return;
			}
			this.checkoutDone();
		},
		addSpace($event){
			const number = $event.replace(/ /g, '');
			this.form.payment_details.number = number ? number.match(/.{1,4}/g).join(' ') : ''
		},
		validateCardExpiry($event) {
			const expiry = $event.replace(/\//g, '');
			if (expiry.match(/^[0-9]{2}$/g)) {
				this.form.payment_details.expiration = expiry + '/'
      }
    },
	stripeFormClass() {
		// to hide stripe form:
		// The reason doing hide is to Stripe doesn't allow to create card element
		// after mounted. So, we need to create card element on mounting.
		if (this.paymentProcessor !== "Stripe") {
			return "hide";
		} else {
			return "";
		}
	},
	createStripeElement(){
		// Create an instance of the card Elements
		this.stripeCardElement = this.elements.create('card', {
			iconStyle: 'solid',
			style: {
				base: {
					iconColor: '#2196f3',
					color: '#000',
					fontWeight: 500,
					fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
					fontSize: '20px',
					fontSmoothing: 'antialiased',

					':-webkit-autofill': {
						color: '#fce883'
					},
					'::placeholder': {
						color: '#000'
					}
				}
			}
		});

		// Mount card Element
		this.stripeCardElement.mount('#card-element');
	}

	},
	computed: {
		...mapGetters({loggedUser:'auth/loggedUser'}),
		currency () {
			return this.$store.getters.getCurrencySymbol;
		},
		tipAmount() {
			return this.$store.getters.getTipAmount;
		},
		items () {
			return this.$store.getters.getItems;
		},
		dining_option_name () {
			return this.$store.getters.getDiningOptionName;
		},
		dining_option_object () {
			return this.$store.getters.getDiningOptionObject;
		},
		totalTax () {
			return this.$store.getters.getTotalTax;
		},
		subtotal () {
			return this.$store.getters.getSubtotal;
		},
		grand_total () {
			if (this.tipAmount) {
				return this.subtotal + this.totalTax + this.tipAmount;
			} else {
				return this.subtotal + this.totalTax
			}
		},
		storeAlias () {
			return this.$store.getters.getStoreAlias;
		},
		payment_type_state () {
			return Boolean(this.form.payment_type);
		},
		serve_at () {
			return this.$store.getters.getServeAt;
		},
		store () {
			return this.$store.getters.getStore;
		},
		paymentTypeOptions () {
			const raw_pms = this.$store.getters.availablePaymentMethods;
			const required_format_pts = raw_pms.map((pt)=> {
				return {value: pt.id, text: pt.name}})
			return required_format_pts;
		},
		showCardInputDetails(){
			/**
			 * checking if the payment have card processing field as true then show the card details form
			*/
			let paymentType = null
			if(this.$store.getters.availablePaymentMethods && this.$store.getters.availablePaymentMethods.length>0){
			paymentType = this.$store.getters.availablePaymentMethods.find(payment=> payment.id === this.form.payment_type)

			if(paymentType)
				return paymentType?.card_processing
			}
			return false
		},
		isStoreClosed () {
			return this.$store.getters.getIsStoreClosed;
		}
	}
};
</script>

<style scoped>
	.hide {
		display: none;
	}
</style>
