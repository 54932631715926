<template>
	<b-modal class="modal" @show="parseProduct" id="modal-product-details" ref="modal-product-details">
		<template #modal-header="{ close }">
			<b-button @click="close()" class="close">
				<svg class="icon icon-close">
					<use xlink:href="#icon-close"></use>
				</svg>
			</b-button>
			<b-img
				v-if="product.photo_thumbnail"
				:src="product.photo_thumbnail"
				fluid-grow
				rounded="top"
				alt="Responsive image"
				class="modifier-thumb"
			/>

			<div class="modifier-top p-3">
				<h2 class="modifier-title mb-2">
					{{ product.name }}
				</h2>

				<div class="modifier-meta">
					{{ product.description ? product.description : "" }}
				</div>
			</div>
		</template>

		<template #default>
			<div class="modifier">
				<div class="modifier-inner">
					<div v-for="(group, groupIndex) in modifierGroups" :key="group.id" class="modifier-group">
						<b-button v-b-toggle="'collapse-' + groupIndex" variant="light" class="modifier-trigger px-3">
							<span>
								<strong>
									{{ group.name }}
									{{
										group.min_selection && group.min_selection > 0
											? `(Required${group.min_selection > 1 ? ` ${group.min_selection}` : ""}*)`
											: ""
									}}
								</strong>
							</span>
							<svg class="icon icon-dd ml-1">
								<use xlink:href="#icon-dd"></use>
							</svg>
						</b-button>

						<b-collapse visible :id="`collapse-${groupIndex}`">
							<div class="modifier-list p-3">
								<b-form-group>
									<b-form-radio-group
										v-if="group.max_selection === 1"
										v-model="group.selected_modifiers"
										:id="`checkbox-group-${groupIndex}`"
										class="d-block"
									>
										<b-form-radio
											v-for="modifier in group.modifiers"
											:key="modifier.id"
											:value="modifier"
											class="modifier-item"
										>
											{{ modifier.name }}
										</b-form-radio>
									</b-form-radio-group>
									<b-form-checkbox-group
										v-else
										v-model="group.selected_modifiers"
										:id="`checkbox-group-${groupIndex}`"
										class="d-block"
									>
										<b-form-checkbox
											v-for="modifier in group.modifiers"
											:key="modifier.id"
											:value="modifier"
											class="modifier-item"
											:disabled="
												group.max_selection &&
													group.selected_modifiers.length >= group.max_selection &&
													group.selected_modifiers.indexOf(modifier) === -1
											"
										>
											{{ modifier.name }}
										</b-form-checkbox>
									</b-form-checkbox-group>
								</b-form-group>
							</div>
						</b-collapse>
					</div>
					<div class="modifier-group">
						<b-button v-b-toggle="'collapse-preferences'" variant="light" class="modifier-trigger px-3">
							<span>
								<strong>Preferences</strong>
							</span>
							<svg class="icon icon-dd ml-1">
								<use xlink:href="#icon-dd"></use>
							</svg>
						</b-button>

						<b-collapse visible :id="`collapse-preferences`">
							<div class="modifier-list p-3">
								<span>Note</span>
								<b-textarea
									v-model="notes"
									placeholder="List any special requests. Example: allergies, extra spicy, etc"
									rows="3"
									max-rows="6"></b-textarea>
							</div>
						</b-collapse>
					</div>
				</div>
			</div>
		</template>

		<template #modal-footer="">
			<div class="modifier-settings d-flex align-items-center justify-content-between">
				<div class="quant" v-if="!isStoreClosed">
					<b-form-spinbutton id="quant-sb" v-model="quantityValue" min="1" max="99"></b-form-spinbutton>
				</div>

				<b-button
					v-if="!isStoreClosed"
					variant="primary"
					:class="{ 'w-100': true }"
					squared
					size="lg"
					@click="addProduct"
					:disabled="!isModifiersCountValid"
				>
					<strong>Add to Cart({{ quantityValue }}) - {{ currency }} {{ totalPrice.toFixed(2) }}</strong>
				</b-button>

				<b-button v-else variant="light" :class="{ 'w-100': true }" squared size="lg" style="pointer-events: none;">
					<strong>Store is Closed</strong>
				</b-button>
				
			</div>
		</template>
	</b-modal>
</template>

<script>
import flattenDepth from "lodash/flattenDepth";

export default {
	name: "ModalProductDetails",
	props: {
		product: { type: Object, default: () => {} },
		storeInfo: { type: Object, default: () => {} },
		addToCart: { type: Function, default: null }
	},
	data () {
		return {
			quantityValue: 1,
			notes: "",
			modifierGroups: []
		};
	},
	beforeUpdate () {
		this.quantityValue = this.product.quantity || 1;
		this.modifierGroups = (this.product.modifier_groups || []).map((group) => {
			const modifiers = group.modifiers?.map((mod) => ({
				...mod, ...{
					group: group.id,
					groupName: group.name
				}
			})) || [];
			const selected_modifiers = modifiers.filter((groupMod) =>
				this.product?.modifiers?.find((m) => m.oryginal === groupMod.id && m.group === group.id)
			);
			return { ...group, modifiers, selected_modifiers };
		});
	},
	computed: {
		currency () {
			return this.$store.getters.getCurrencySymbol;
		},
		isModifiersCountValid () {
			return this.modifierGroups.every((group) => {
				const { min_selection, max_selection } = group;
				if (!min_selection) {
					return true;
				}
				const selectedCount = this.selectedModifiers.filter((mod) => group.id === mod.group).length;
				return selectedCount >= min_selection && selectedCount <= max_selection;
			});
		},
		selectedModifiers () {
			const modifiers = this.modifierGroups.map((group) => group.selected_modifiers).filter(Boolean);
			return flattenDepth(modifiers, 1);
		},
		totalPrice () {
			const modifiersTotal = this.selectedModifiers
				.map((modifier) => modifier.price)
				.reduce((total, modifierPrice) => (total += modifierPrice), 0);
			const totalPricePerOne = (modifiersTotal ?? 0) + this.product.price;
			return totalPricePerOne * this.quantityValue;
		},
		isStoreClosed () {
			return this.$store.getters.getIsStoreClosed;
		}
	},
	methods: {
		parseProduct () {
			this.$forceUpdate();
		},
		addProduct () {
			if (this.isModifiersCountValid) {
				this.addToCart({
					quantity: this.quantityValue,
					modifiers: this.prepareModifiers(this.selectedModifiers),
					notes: this.notes
				});
				this.notes = "";
			}
		},
		prepareModifiers (modifiers) {
			return modifiers.map((m) => ({
				cost: m.cost,
				group: m.group,
				name: m.name,
				groupName: m.groupName,
				oryginal: m.id,
				price: m.price
			}));
		}
	}
};
</script>

<style lang="scss" scoped>
#modal-product-details {
	.btn {
		&.modifier-trigger[aria-expanded='true'] {
			.icon {
				transition: 0.3s;
				transform: rotate(180deg);
			}
		}

		.icon {
			transition: 0.3s;
		}
	}
}
</style>
