export const toFixed = (num, precision) => {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  };

export const toFixedNoRounding = (num, precision) => {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (precision || -1) + '})?');
    return num.toString().match(re)[0];
}

export const setTaxRound = (storeInfo ,tax) => {
    if (storeInfo === 'nearest') {
        return parseFloat(toFixed(tax, 2));
    } else if (storeInfo === 'no') {
        console.log('not working')
        return parseFloat(toFixedNoRounding(tax, 2));
    }
}